 <template>
  <div class="header" style="width: 100%">
    <el-header class="flex_between flex_items">
      <span
        class="f-center"
        style="font-size: 18px; padding: 0; margin-left: 52px"
        >益鸟维修CC管理系统</span
      >
      <el-main
        style="
          text-align: right;
          font-size: 12px;
          padding: 0;
          display: inline-block;
        "
      >
        <el-dropdown>
          <i
            class="el-icon-setting"
            style="margin-right: 15px; color: white"
          ></i>
          <el-dropdown-menu slot="dropdown">
            <!-- <el-dropdown-item @click.native="chakan">查看</el-dropdown-item>
            <el-dropdown-item>新增</el-dropdown-item>-->
            <el-dropdown-item @click.native="updatapass"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item @click.native="goout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span>{{ username }}</span>
      </el-main>
    </el-header>
    <!-- <div style="display: flex;
    justify-content: center;
    margin-top: 30px;">123321~~</div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: "",
      newOrder2:0
    };
  },
  mounted() {
    // if (sessionStorage.getItem("token") == null) {
    //   this.$message({
    //     message: "您还未登录账号，请先登录",
    //     type: "warning",
    //     duration: "1500"
    //   });
    //   setTimeout(() => {
    //     this.$router.replace({ name: "login" });
    //   }, 1500);
    // } else {
    this.username = sessionStorage.getItem("username");
    // }
  },
  methods: {
    goout() {
      this.util
        .usergetout(this.HOST + "/Login/logout", {
          accesstoken: sessionStorage.getItem("token"),
        })
        .then((res) => {
          if (res.code == 200) {
            sessionStorage.clear();
            this.$router.push({ name: "login" });
          }else{
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset:90
            });
          }
        });
    },
    updatapass() {
      this.$router.push({
        name: "updatapass",
        params: { admin: sessionStorage.getItem("admin") },
      });
    },
  },
};
</script>
<style>
.el-header {
  background-color: #04223a;
  color: white;
  font-weight: bold;
  line-height: 60px;
}
</style>
